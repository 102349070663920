import classNames from "classnames";
import { jwtVerify } from "jose";
import type { GetServerSidePropsContext } from "next";
import { getCsrfToken, signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FaGoogle } from "react-icons/fa";

import { SAMLLogin } from "@calcom/features/auth/SAMLLogin";
import { isSAMLLoginEnabled, samlProductID, samlTenantID } from "@calcom/features/ee/sso/lib/saml";
import { ErrorCode, getSession } from "@calcom/lib/auth";
import { WEBAPP_URL, WEBSITE_URL } from "@calcom/lib/constants";
import { getSafeRedirectUrl } from "@calcom/lib/getSafeRedirectUrl";
import { useLocale } from "@calcom/lib/hooks/useLocale";
import { collectPageParameters, telemetryEventTypes, useTelemetry } from "@calcom/lib/telemetry";
import prisma from "@calcom/prisma";
import { Alert, Button, EmailField, PasswordField } from "@calcom/ui";
import { FiArrowLeft } from "@calcom/ui/components/icon";

import type { inferSSRProps } from "@lib/types/inferSSRProps";
import type { WithNonceProps } from "@lib/withNonce";
import withNonce from "@lib/withNonce";

import AddToHomescreen from "@components/AddToHomescreen";
import TwoFactor from "@components/auth/TwoFactor";
import AuthContainer from "@components/ui/AuthContainer";

import { IS_GOOGLE_LOGIN_ENABLED } from "@server/lib/constants";
import { ssrInit } from "@server/lib/ssr";

interface LoginValues {
  email: string;
  password: string;
  totpCode: string;
  csrfToken: string;
}

export default function Login({
  csrfToken,
  isGoogleLoginEnabled,
  isSAMLLoginEnabled,
  samlTenantID,
  samlProductID,
  totpEmail,
}: inferSSRProps<typeof _getServerSideProps> & WithNonceProps) {
  const { t } = useLocale();
  const router = useRouter();
  const methods = useForm<LoginValues>();

  const { register, formState } = methods;
  const [twoFactorRequired, setTwoFactorRequired] = useState(!!totpEmail || false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const errorMessages: { [key: string]: string } = {
    // [ErrorCode.SecondFactorRequired]: t("2fa_enabled_instructions"),
    // Don't leak information about whether an email is registered or not
    [ErrorCode.IncorrectUsernamePassword]: t("incorrect_username_password"),
    [ErrorCode.IncorrectTwoFactorCode]: `${t("incorrect_2fa_code")} ${t("please_try_again")}`,
    [ErrorCode.InternalServerError]: `${t("something_went_wrong")} ${t("please_try_again_and_contact_us")}`,
    [ErrorCode.ThirdPartyIdentityProviderEnabled]: t("account_created_with_identity_provider"),
  };

  const telemetry = useTelemetry();

  let callbackUrl = typeof router.query?.callbackUrl === "string" ? router.query.callbackUrl : "";

  if (/"\//.test(callbackUrl)) callbackUrl = callbackUrl.substring(1);

  // If not absolute URL, make it absolute
  if (!/^https?:\/\//.test(callbackUrl)) {
    callbackUrl = `${WEBAPP_URL}/${callbackUrl}`;
  }

  const safeCallbackUrl = getSafeRedirectUrl(callbackUrl);

  callbackUrl = safeCallbackUrl || "";

  const LoginFooter = (
    <a href={`${WEBSITE_URL}/signup`} className="text-brand-500 font-medium">
      {t("dont_have_an_account")}
    </a>
  );

  const TwoFactorFooter = (
    <Button
      onClick={() => {
        setTwoFactorRequired(false);
        methods.setValue("totpCode", "");
      }}
      StartIcon={FiArrowLeft}
      color="minimal">
      {t("go_back")}
    </Button>
  );

  const ExternalTotpFooter = (
    <Button
      onClick={() => {
        window.location.replace("/");
      }}
      color="minimal">
      {t("cancel")}
    </Button>
  );

  const onSubmit = async (values: LoginValues) => {
    setErrorMessage(null);
    telemetry.event(telemetryEventTypes.login, collectPageParameters());
    const res = await signIn<"credentials">("credentials", {
      ...values,
      callbackUrl,
      redirect: false,
    });
    if (!res) setErrorMessage(errorMessages[ErrorCode.InternalServerError]);
    // we're logged in! let's do a hard refresh to the desired url
    else if (!res.error) router.push(callbackUrl);
    // reveal two factor input if required
    else if (res.error === ErrorCode.SecondFactorRequired) setTwoFactorRequired(true);
    // fallback if error not found
    else setErrorMessage(errorMessages[res.error] || t("something_went_wrong"));
  };

  return (
    <>
      <AuthContainer
        title={t("login")}
        description={t("login")}
        heading="Access Your Billing Portal"
        subHeading="Access & manage all your billing"
        footerText={twoFactorRequired ? (!totpEmail ? TwoFactorFooter : ExternalTotpFooter) : null}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} data-testid="login-form">
            <div>
              <input defaultValue={csrfToken || undefined} type="hidden" hidden {...register("csrfToken")} />
            </div>
            <div className="space-y-6">
              <div className={classNames("space-y-6", { hidden: twoFactorRequired })}>
                <div className="relative">
                  <EmailField
                    id="email"
                    label="Email"
                    defaultValue={totpEmail || (router.query.email as string)}
                    placeholder="john.doe@example.com"
                    required
                    className="border border-gray-200 pl-8 text-gray-900"
                    {...register("email")}
                  />
                  <div className="absolute top-[30px] left-1">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.125 6.125C1.125 4.67525 2.30025 3.5 3.75 3.5H16.25C17.6997 3.5 18.875 4.67525 18.875 6.125V14.875C18.875 16.3247 17.6997 17.5 16.25 17.5H3.75C2.30025 17.5 1.125 16.3247 1.125 14.875V6.125ZM2.625 6.125V6.32726C2.625 6.71793 2.82767 7.08063 3.16039 7.28537L9.41039 11.1315C9.77197 11.354 10.228 11.354 10.5896 11.1315L16.8396 7.28537C17.1723 7.08063 17.375 6.71793 17.375 6.32726V6.125C17.375 5.50368 16.8713 5 16.25 5H3.75C3.12868 5 2.625 5.50368 2.625 6.125ZM17.375 8.71717L11.3758 12.409C10.5321 12.9282 9.46794 12.9282 8.62424 12.409L2.625 8.71717V14.875C2.625 15.4963 3.12868 16 3.75 16H16.25C16.8713 16 17.375 15.4963 17.375 14.875V8.71717Z"
                        fill="#7E8892"
                      />
                    </svg>
                  </div>
                </div>
                <div className="relative">
                  {/*<div className="absolute -top-[6px]  z-10 ltr:right-0 rtl:left-0">*/}
                  {/*  <Link*/}
                  {/*    href="/auth/forgot-password"*/}
                  {/*    tabIndex={-1}*/}
                  {/*    className="text-sm font-medium text-gray-600">*/}
                  {/*    {t("forgot")}*/}
                  {/*  </Link>*/}
                  {/*</div>*/}
                  <PasswordField
                    id="password"
                    autoComplete="off"
                    required={!totpEmail}
                    className="mb-0 border border-gray-200 pl-8 text-gray-900"
                    {...register("password")}
                  />
                  <div className="absolute top-[30px] left-1">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.375 4.875C12.375 4.46079 12.7108 4.125 13.125 4.125C14.9199 4.125 16.375 5.58007 16.375 7.375C16.375 7.78921 16.0392 8.125 15.625 8.125C15.2108 8.125 14.875 7.78921 14.875 7.375C14.875 6.4085 14.0915 5.625 13.125 5.625C12.7108 5.625 12.375 5.28921 12.375 4.875Z"
                        fill="#7E8892"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.375 7.375C7.375 4.19936 9.94936 1.625 13.125 1.625C16.3006 1.625 18.875 4.19936 18.875 7.375C18.875 10.5506 16.3006 13.125 13.125 13.125C12.7897 13.125 12.4606 13.0962 12.1401 13.0408C11.845 12.9899 11.6171 13.0686 11.4953 13.1903L9.28033 15.4053C9.13968 15.546 8.94891 15.625 8.75 15.625H7.625V16.75C7.625 17.1642 7.28921 17.5 6.875 17.5H5.75V18.625C5.75 19.0392 5.41421 19.375 5 19.375H1.875C1.46079 19.375 1.125 19.0392 1.125 18.625V16.2767C1.125 15.5805 1.40156 14.9128 1.89384 14.4205L7.30967 9.00467C7.43145 8.88289 7.51013 8.65499 7.45915 8.35994C7.40377 8.03941 7.375 7.71027 7.375 7.375ZM13.125 3.125C10.7778 3.125 8.875 5.02779 8.875 7.375C8.875 7.62431 8.89639 7.86802 8.93725 8.10456C9.04841 8.74789 8.92191 9.51375 8.37033 10.0653L2.9545 15.4812C2.74353 15.6921 2.625 15.9783 2.625 16.2767V17.875H4.25V16.75C4.25 16.3358 4.58579 16 5 16H6.125V14.875C6.125 14.4608 6.46079 14.125 6.875 14.125H8.43934L10.4347 12.1297C10.9863 11.5781 11.7521 11.4516 12.3954 11.5627C12.632 11.6036 12.8757 11.625 13.125 11.625C15.4722 11.625 17.375 9.72221 17.375 7.375C17.375 5.02779 15.4722 3.125 13.125 3.125Z"
                        fill="#7E8892"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              {twoFactorRequired && <TwoFactor center />}

              {errorMessage && <Alert severity="error" title={errorMessage} />}
              <Button
                type="submit"
                color="primary"
                disabled={formState.isSubmitting}
                className="w-full justify-center bg-black hover:bg-black hover:opacity-90">
                {twoFactorRequired ? t("submit") : "Login"}
              </Button>
            </div>
          </form>
          {!twoFactorRequired && (
            <>
              {(isGoogleLoginEnabled || isSAMLLoginEnabled) && <hr className="my-8" />}
              <div className="space-y-3">
                {isGoogleLoginEnabled && (
                  <Button
                    color="secondary"
                    className="w-full justify-center"
                    data-testid="google"
                    StartIcon={FaGoogle}
                    onClick={async (e) => {
                      e.preventDefault();
                      await signIn("google");
                    }}>
                    {t("signin_with_google")}
                  </Button>
                )}
                {isSAMLLoginEnabled && (
                  <SAMLLogin
                    samlTenantID={samlTenantID}
                    samlProductID={samlProductID}
                    setErrorMessage={setErrorMessage}
                  />
                )}
              </div>
            </>
          )}
        </FormProvider>
      </AuthContainer>
      <AddToHomescreen />
    </>
  );
}

// TODO: Once we understand how to retrieve prop types automatically from getServerSideProps, remove this temporary variable
const _getServerSideProps = async function getServerSideProps(context: GetServerSidePropsContext) {
  const { req } = context;
  const session = await getSession({ req });
  const ssr = await ssrInit(context);

  const verifyJwt = (jwt: string) => {
    const secret = new TextEncoder().encode(process.env.CALENDSO_ENCRYPTION_KEY);

    return jwtVerify(jwt, secret, {
      issuer: WEBSITE_URL,
      audience: `${WEBSITE_URL}/auth/login`,
      algorithms: ["HS256"],
    });
  };

  let totpEmail = null;
  if (context.query.totp) {
    try {
      const decryptedJwt = await verifyJwt(context.query.totp as string);
      if (decryptedJwt.payload) {
        totpEmail = decryptedJwt.payload.email as string;
      } else {
        return {
          redirect: {
            destination: "/auth/error?error=JWT%20Invalid%20Payload",
            permanent: false,
          },
        };
      }
    } catch (e) {
      return {
        redirect: {
          destination: "/auth/error?error=Invalid%20JWT%3A%20Please%20try%20again",
          permanent: false,
        },
      };
    }
  }

  if (session) {
    return {
      redirect: {
        destination: "/",
        permanent: false,
      },
    };
  }

  const userCount = await prisma.user.count();
  if (userCount === 0) {
    // Proceed to new onboarding to create first admin user
    return {
      redirect: {
        destination: "/auth/setup",
        permanent: false,
      },
    };
  }
  return {
    props: {
      csrfToken: await getCsrfToken(context),
      trpcState: ssr.dehydrate(),
      isGoogleLoginEnabled: IS_GOOGLE_LOGIN_ENABLED,
      isSAMLLoginEnabled,
      samlTenantID,
      samlProductID,
      totpEmail,
    },
  };
};

export const getServerSideProps = withNonce(_getServerSideProps);
