import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { trpc } from "@calcom/trpc/react";
import { Select } from "@calcom/ui";

import { BuildingIcon } from "@components/Icons";

import { useOfficeContext } from "./OfficeProvider";

const groupViewLinks: string[] = ["/home", "/transactions", "/insights", "users"];

export default function OfficeSelect() {
  const router = useRouter();
  const { currentOffice, setCurrentOffice } = useOfficeContext();
  const [officeOptions, setOfficeOptions] = useState<{ value: string; label: string }[]>([]);

  const { data: officeData } = trpc.viewer.office.list.useQuery();

  useEffect(() => {
    if (officeData) {
      setOfficeOptions(officeData);
      setCurrentOffice(currentOffice || Number(officeData[0].value));
    }
  }, [officeData]);

  return (
    <div className="relative flex w-full items-center md:w-[300px]">
      <Select
        instanceId="office"
        options={officeOptions}
        hasIcon={true}
        styles={{
          control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderColor: "rgb(229 231 235)",
            border: "none",
            backgroundColor: "transparent",
            paddingLeft: "20px",
          }),
          valueContainer: (defaultStyles, state) => ({
            ...defaultStyles,
            width: "100px",
          }),
          dropdownIndicator: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: 0,
          }),
          container: (defaultStyles, state) => ({
            ...defaultStyles,
            // minWidth: "320px",
            backgroundColor: "transparent",
          }),
          menu: (defaultStyles, state) => ({
            ...defaultStyles,
            backgroundColor: "transparent!important",
            borderWidth: 0,
            padding: 0,
          }),
          menuList: (defaultStyles, state) => ({
            ...defaultStyles,
            minWidth: "350px",
            borderRadius: "6px",
            borderWidth: "1px",
            padding: "4px",
            backgroundColor: "white",
          }),
        }}
        value={
          groupViewLinks.includes(router.asPath.split("?")[0])
            ? undefined
            : officeOptions.find((option) => +option.value == currentOffice)
        }
        placeholder="Select an Office"
        onChange={(option) => {
          if (option) {
            setCurrentOffice(Number(option.value));
            if (groupViewLinks.includes(router.asPath.split("?")[0])) router.push(`/practice`);
          }
        }}
      />
      <BuildingIcon className="absolute left-2 h-4 w-4 cursor-pointer text-gray-500" />
    </div>
  );
}
