import { zodResolver } from "@hookform/resolvers/zod";
import { PracticeManagement } from "@prisma/client";
import React, { useEffect, useState, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import type { RouterOutputs } from "@calcom/trpc/react";
import { trpc } from "@calcom/trpc/react";
import { Button, TextField, Select, Dialog, DialogContent, Switch, Form, Label, showToast } from "@calcom/ui";

import { practiceList, timeZoneList } from "@lib/constant";

type Office = RouterOutputs["viewer"]["office"]["get"][number];

type CreateOfficeDialogProps = {
  open: boolean;
  handler: React.Dispatch<React.SetStateAction<boolean>>;
  isNew: boolean;
  officeId?: number | null;
};

type FormValues = {
  id: number;
  name: string;
  officename: string; // unique
  email: string;
  phone: string;
  practice: PracticeManagement;
  isNexHealthIntegrated: boolean;
  logo: string;
  timeZone: string;
  address1: string;
  twilioPhone: string;
  merchantId: string;
  headerColor: string;
  buttonColor: string;
  showUploadButton: boolean;
  applyInsuranceAutomationFlow: boolean;
  groupId: number;
};

export const CreateOfficeDialog = ({ open, handler, isNew, officeId }: CreateOfficeDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { data: groupOption } = trpc.viewer.office.groupNameList.useQuery();

  const trpcUtil = trpc.useContext();
  const defaultValues = {
    name: "",
    officename: "", // unique
    isNexHealthIntegrated: false,
    twilioPhone: "8444794423",
    logo: "https://images.caresuite.app/billingarlogos/{UNIQUE NAME}.png",
    showUploadButton: true,
    applyInsuranceAutomationFlow: true,
  };

  const { data: formData } = trpc.viewer.office.getById.useQuery({
    officeId: officeId ?? -1,
  });

  const createOffice = trpc.viewer.office.create.useMutation({
    onSuccess: (data) => {
      if (data.success) {
        trpcUtil.viewer.office.get.invalidate();
      }
      setIsLoading(false);
      showToast(data.message, data.success ? "success" : "error");
    },
    onError: (error) => {
      setIsLoading(false);
      showToast(error.message, "error");
    },
  });

  const updateOffice = trpc.viewer.office.update.useMutation({
    onSuccess: (data) => {
      if (data.success) {
        trpcUtil.viewer.office.get.invalidate();
      }
      setIsLoading(false);
      showToast(data.message, data.success ? "success" : "error");
    },
    onError: (error) => {
      setIsLoading(false);
      showToast(error.message, "error");
    },
  });

  const form = useForm<FormValues>({
    defaultValues: useMemo(
      () =>
        formData
          ? {
              ...defaultValues,
              ...Object.fromEntries(Object.entries(formData).filter(([_, v]) => v != null)),
            }
          : defaultValues,
      [formData]
    ),
    resolver: zodResolver(
      z.object({
        id: z.number().optional(),
        name: z.string().min(5, "Required"),
        officename: z.string().min(5, "Required"), // unique
        email: z.string().optional().nullable(),
        phone: z.string().optional(),
        // apiKey: z.string().optional(),
        // subdomain: z.string().optional(),
        // locationId: z.number(),
        practice: z.nativeEnum(PracticeManagement).optional(),
        isNexHealthIntegrated: z.boolean(),
        logo: z.string().optional(),
        timeZone: z.string().min(5, "Required"),
        address1: z.string().optional(),
        twilioPhone: z.string().optional(),
        merchantId: z.string().optional(),
        headerColor: z.string().optional(),
        buttonColor: z.string().optional(),
        showUploadButton: z.boolean().optional(),
        applyInsuranceAutomationFlow: z.boolean().optional(),
        groupId: z.number(),
      })
    ),
  });

  const { register, formState, control, reset, watch, setValue } = form;

  useEffect(() => {
    reset(
      formData
        ? {
            ...defaultValues,
            ...Object.fromEntries(Object.entries(formData).filter(([_, v]) => v != null)),
          }
        : defaultValues
    );
  }, [formData]);

  const watchOfficename = watch("officename");
  const watchName = watch("name");

  useEffect(() => {
    if (isNew) {
      setValue("officename", watchName.trim().replace(/ /g, "-").toLowerCase());
    }
  }, [isNew, watchName, setValue]);

  useEffect(() => {
    if (isNew) {
      setValue("logo", `https://images.caresuite.app/billingarlogos/${watchOfficename}.png`);
    }
  }, [watchOfficename, setValue]);

  const handleSubmit = async (data: FormValues) => {
    setIsLoading(true);
    if (isNew) {
      createOffice.mutate(data);
    } else {
      updateOffice.mutate(data);
    }
  };

  return (
    <Dialog open={open} onOpenChange={handler}>
      <DialogContent className="w-full !max-w-[750px] bg-white px-0 text-gray-600">
        <p className="border-b px-8 pb-4 text-base font-medium">
          {isNew ? "Create New Office" : "Update Office"}
        </p>
        <Form className="w-full px-8" form={form} handleSubmit={handleSubmit}>
          <div className="mb-4 w-full md:w-1/2 md:pr-3">
            <Controller
              name="groupId"
              render={({ field: { value, onChange } }) => (
                <>
                  <Label>Group*</Label>
                  <Select
                    className="w-full"
                    instanceId="groupId"
                    options={groupOption}
                    value={groupOption?.find((p) => p.value === value)}
                    onChange={(option) => option && onChange(option.value)}
                  />
                </>
              )}
            />
            {formState.errors.groupId && (
              <div className="text-gray mt-2 flex items-center gap-x-2 text-sm text-red-700">
                <div>
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="h-3 w-3"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="10" />
                    <line x1="12" y1="16" x2="12" y2="12" />
                    <line x1="12" y1="8" x2="12.01" y2="8" />
                  </svg>
                </div>
                <p>Required</p>
              </div>
            )}
          </div>
          <div className="-mx-3 mb-2 flex flex-wrap">
            <div className="mb-2 w-full px-3 md:mb-0 md:w-1/2">
              <TextField label="Office Name*" className="w-full border-gray-200" {...register("name")} />
            </div>
            <div className="mb-2 w-full px-3 md:mb-0 md:w-1/2">
              <TextField
                label="Unique Name*"
                className="w-full border-gray-200"
                disabled={!isNew}
                {...register("officename")}
                placeholder="cental-dental"
              />
            </div>
          </div>
          <div className="-mx-3 mb-2 flex flex-wrap">
            <div className="mb-2 w-full px-3 md:mb-0 md:w-1/2">
              <TextField label="Email" className="w-full border-gray-200" {...register("email")} />
            </div>
            <div className="mb-2 w-full px-3 md:mb-0 md:w-1/2">
              <TextField
                label="Phone"
                className="w-full border-gray-200"
                {...register("phone")}
                placeholder="(123)456-7890"
              />
            </div>
          </div>
          <div className="-mx-3 mb-2 flex flex-wrap">
            <div className="mb-2 w-full px-3 md:mb-0">
              <TextField label="Address" className="w-full border-gray-200" {...register("address1")} />
            </div>
          </div>
          <div className="-mx-3 mb-2 flex flex-wrap">
            <div className="mb-2 w-full px-3 md:mb-0 md:w-1/2">
              <Controller
                name="practice"
                render={({ field: { value, onChange } }) => (
                  <>
                    <Label>PMS System</Label>
                    <Select
                      className="w-full"
                      instanceId="pmssystem"
                      options={practiceList}
                      value={practiceList.find((p) => p.value === value)}
                      onChange={(option) => option && onChange(option.value)}
                    />
                  </>
                )}
              />
            </div>
            <div className="mb-2 w-full px-3 md:mb-0 md:w-1/2">
              <Controller
                name="timeZone"
                render={({ field: { value, onChange } }) => (
                  <>
                    <Label>Timezone</Label>
                    <Select
                      className="w-full"
                      instanceId="timezone"
                      options={timeZoneList}
                      value={timeZoneList.find((p) => p.value === value)}
                      onChange={(option) => option && onChange(option.value)}
                    />
                  </>
                )}
              />
              {formState.errors.timeZone && (
                <div className="text-gray mt-2 flex items-center gap-x-2 text-sm text-red-700">
                  <div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="h-3 w-3"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="10" />
                      <line x1="12" y1="16" x2="12" y2="12" />
                      <line x1="12" y1="8" x2="12.01" y2="8" />
                    </svg>
                  </div>
                  <p>Required</p>
                </div>
              )}
            </div>
          </div>
          <div className="-mx-3 my-1 flex flex-wrap">
            <div className="mb-2 w-full px-3 md:mb-0">
              <TextField label="Logo Link" className="w-full border-gray-200" {...register("logo")} />
            </div>
          </div>
          <div className="-mx-3 mb-2 flex flex-wrap">
            <div className="mb-2 w-full px-3 md:mb-0 md:w-1/2">
              <TextField
                label="Header Color"
                className="w-full border-gray-200"
                {...register("headerColor")}
                placeholder="#000000"
              />
            </div>
            <div className="mb-2 w-full px-3 md:mb-0 md:w-1/2">
              <TextField
                label="Button Color"
                className="w-full border-gray-200"
                {...register("buttonColor")}
                placeholder="#000000"
              />
            </div>
          </div>

          <div className="-mx-3 mb-2 flex hidden flex-wrap">
            <div className="mb-3 w-full px-3">
              <Controller
                name="isNexHealthIntegrated"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch label="NEXHEALTH Integrated" checked={value} onCheckedChange={onChange} />
                )}
              />
            </div>
            {/* <div className="mb-2 hidden w-full px-3">
                <TextField label="API KEY" className="w-full" {...register("apiKey")} />
              </div>
              <div className="mb-2 hidden w-full px-3 md:mb-0 md:w-1/2">
                <TextField label="SUBDOMAIN" className="w-full" {...register("subdomain")} />
              </div>
              <div className="mb-2 hidden w-full px-3 md:mb-0 md:w-1/2">
                <TextField
                  label="LOCATIONID*"
                  type="number"
                  className="w-full"
                  {...register("locationId", { valueAsNumber: true })}
                />
              </div> */}
          </div>
          <div className="-mx-3 mb-2 flex flex-wrap">
            <div className="mb-2 w-full px-3 md:w-1/2 ">
              <TextField
                label="FINIX Merchant ID"
                className="w-full border-gray-200"
                {...register("merchantId")}
              />
            </div>
            <div className="mb-2 w-full px-3 md:w-1/2 ">
              <TextField
                label="TELNYX Phone"
                className="hidden w-full border-gray-200"
                {...register("twilioPhone")}
              />
            </div>
          </div>
          <div className="-mx-3 mb-2 flex flex-wrap">
            <div className="mb-2 w-full px-3 md:w-1/2">
              <Controller
                name="showUploadButton"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch label="Show Upload Dialog" checked={value} onCheckedChange={onChange} />
                )}
              />
            </div>
            <div className="mb-2 w-full px-3 md:w-1/2">
              <Controller
                name="applyInsuranceAutomationFlow"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch label="Apply Insurance EST" checked={value} onCheckedChange={onChange} />
                )}
              />
            </div>
          </div>
          <div className=" flex justify-end border-t pt-4">
            <Button
              type="submit"
              loading={isLoading}
              color="secondary"
              className="border bg-white !text-gray-600 hover:bg-white hover:text-gray-600">
              {isNew ? "Save" : "Update"}
            </Button>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
