import classNames from "classnames";
import Link from "next/link";
import React, { useState } from "react";

import { trpc } from "@calcom/trpc/react";
import { Dialog, DialogContent } from "@calcom/ui";

import PaymentRequest from "@components/patients/paymentRequest";
import TerminalPayment from "@components/patients/terminalPayment";
import VirtualPayment from "@components/patients/virtualPayment";

type CreatePaymentDlgProps = {
  open: boolean;
  handler: React.Dispatch<React.SetStateAction<boolean>>;
  patientId?: number;
  officeId: number;
  appId: string;
  env: string;
};

type TabTitle = "Virtual Payment" | "Terminal Payment" | "Payment Request";

type TabType = {
  title: TabTitle;
  isActive: boolean;
};

const tabs: TabType[] = [
  { title: "Virtual Payment", isActive: true },
  { title: "Terminal Payment", isActive: true },
  { title: "Payment Request", isActive: true },
];

export default function CreatePaymentDialog(props: CreatePaymentDlgProps) {
  const { open, handler, patientId, officeId, appId, env } = props;

  const { data: office } = trpc.viewer.office.getById.useQuery({
    officeId: officeId,
  });

  const [activeTab, setActiveTab] = useState<TabTitle>("Virtual Payment");

  const display = () => {
    switch (activeTab) {
      case "Virtual Payment":
        return <VirtualPayment patientId={patientId} officeId={officeId} appId={appId} env={env} />;
      case "Terminal Payment":
        return <TerminalPayment patientId={patientId} officeId={officeId} />;
      case "Payment Request":
        return <PaymentRequest patientId={patientId} officeId={officeId} officename={office?.officename} />;
      default:
        break;
    }
  };

  return (
    <Dialog open={open} onOpenChange={handler}>
      <DialogContent className="no-scrollbar overflow-auto !p-0">
        <div className="border-b border-gray-200 p-4 pb-4 md:p-8 md:pb-4">
          <div className="flex items-center justify-between">
            <span className="text-base font-medium text-gray-600">Create Payment</span>
            <Link
              href={`${process.env.NEXT_PUBLIC_WEBAPP_URL}/${office?.officename}/payment-link`}
              className="text-xs text-gray-600">
              View Payment Link
            </Link>
          </div>
        </div>
        <div className="space-y-6">
          <div className="p-4 pt-0 md:p-8 md:pt-0">
            <div className="grid grid-cols-1 gap-1 rounded-xl bg-gray-100 p-1 md:grid-cols-3">
              {tabs.map((tab, key) => (
                <span
                  key={key}
                  className={classNames("cursor-pointer rounded-xl px-4 py-2 text-sm text-gray-900", {
                    "bg-white": activeTab === tab.title,
                  })}
                  onClick={() => setActiveTab(tab.title)}>
                  {tab.title}
                </span>
              ))}
            </div>
            {display()}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
